<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <!-- <v-icon>layers</v-icon> -->
            Balance Sheet
            <v-spacer></v-spacer>
            <v-btn
              outlined
              small
              fab
              color="primary"
              @click="vertical = !vertical"
            >
              <v-icon v-if="!vertical" key="0">view_stream</v-icon>
              <v-icon v-if="vertical" style="transform: rotate(90deg)" key="1"
                >view_stream</v-icon
              >
            </v-btn>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title" style="margin-top: 10px">
              <v-flex xs12 sm3>
                <!-- <v-text-field
                  outlined
                  dense
                  label="From Date"
                  v-model="trialbalance.filter_date.from_date"
                  prepend-icon="event"
                  mask="####-##-##"
                  placeholder="YYYY-MM-DD"
                  :height="25"
                /> -->
                <v-calendar-field
                  outlined
                  dense
                  id-val="today-bs-1"
                  label="From Date"
                  v-model="trialbalance.filter_date.from_date"
                  prepend-icon="event"
                  placeholder="YYYY-MM-DD"
                  :height="25"
                />
              </v-flex>
              <v-flex xs12 sm3>
                <!-- <v-text-field
                  outlined
                  dense
                  label="To date"
                  v-model="trialbalance.filter_date.to_date"
                  prepend-icon="event"
                  mask="####-##-##"
                  placeholder="YYYY-MM-DD"
                  :height="25"
                /> -->
                <v-calendar-field
                  outlined
                  dense
                  id-val="today-bs-2"
                  label="From Date"
                  v-model="trialbalance.filter_date.to_date"
                  prepend-icon="event"
                  placeholder="YYYY-MM-DD"
                  :height="25"
                />
              </v-flex>
              <v-flex xs12 sm6 style="text-align: right">
                <!-- <v-btn
                  fab
                  small
                  style="z-index: 1; background: blue; margin-right: 10px"
                  dark
                  @click="downloadBalanceSheet"
                >
                  <v-icon dark>cloud_download</v-icon>
                </v-btn> -->
                <v-download-column-select
                  @onDownload="downloadBalanceSheet"
                  :showPDF="false"
                >
                  DOWNLOAD BALANCE SHEET
                </v-download-column-select>
                <add-button
                  permission="section-create"
                  @action="getTrials"
                  icon="search"
                  >Search
                </add-button>
              </v-flex>
            </v-card-title>
          </v-card>
        </v-card>
        <div class="v-table__overflow">
          <table
            border="1"
            padding="0"
            style="min-width: 600px"
            v-if="vertical"
          >
            <thead>
              <tr>
                <th style="text-align: left">Particulars</th>
                <th style="width: 150px">Amount (Rs.)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(t, k) in tree" :key="k">
                <td colspan="2">
                  <balance-tree
                    :tree-data="t"
                    :root="k"
                    type="tb1"
                  ></balance-tree>
                  <!--profit-->
                  <div v-if="k === 0 && pl < 0" class="pldiv">
                    <div style="width: 87%; padding-right: 2px">
                      <span class="pl"> <strong>Net Loss</strong></span>
                    </div>
                    <div style="width: 17%">
                      <span>{{ pl | fixAccount }}</span>
                    </div>
                  </div>
                  <div v-if="k === 0 && diffAL < 0" class="pldiv">
                    <div style="width: 91%">
                      <span class="p"> <strong>Difference</strong></span>
                    </div>
                    <div style="width: 12%">
                      <strong>{{ diffAL | fixAccount }}</strong>
                    </div>
                  </div>
                  <div v-if="k === 0" class="pldiv">
                    <div style="width: 91%">
                      <span class="p"> <strong>Total</strong></span>
                    </div>
                    <div style="width: 12%">
                      <strong v-if="diffAL < 0">{{
                        (Math.abs(assetsSum) + Math.abs(diffAL)) | fixAccount
                      }}</strong>
                      <strong v-else>{{ assetsSum | fixAccount }}</strong>
                    </div>
                  </div>

                  <div v-if="k === 1 && pl > 0" class="pldiv">
                    <div style="width: 87%; padding-right: 2px">
                      <span class="pl"> <strong>Net Profit</strong></span>
                    </div>
                    <div style="width: 17%">
                      <span>{{ pl | fixAccount }}</span>
                    </div>
                  </div>
                  <div v-if="k === 1 && diffAL > 0" class="pldiv">
                    <div style="width: 91%">
                      <span class="p"> <strong>Difference</strong></span>
                    </div>
                    <div style="width: 12%">
                      <strong>{{ diffAL | fixAccount }}</strong>
                    </div>
                  </div>
                  <div v-if="k === 1" class="pldiv">
                    <div style="width: 91%">
                      <span class="p"> <strong>Total</strong></span>
                    </div>
                    <div style="width: 12%">
                      <strong v-if="diffAL > 0">{{
                        (Math.abs(liabilitiesSum) + Math.abs(diffAL))
                          | fixAccount
                      }}</strong>
                      <strong v-else>{{ liabilitiesSum | fixAccount }}</strong>
                    </div>
                  </div>

                  <!--&lt;!&ndash;loss&ndash;&gt;-->
                  <!--<div v-if="plAmount < 0 && k===1" class="pldiv">-->
                  <!--<div style="width: 87%; padding-right: 2px;"><span-->
                  <!--class="pl"> <strong>Net Loss</strong></span></div>-->
                  <!--<div style="width: 17%"><strong>{{Math.abs(plAmount).numberFormat()}} </strong>-->
                  <!--</div>-->
                  <!--</div>-->
                  <!--<div v-if="k===1" style="pldiv">-->
                  <!--<div style="width: 91%"><span class="p"> <strong>Total</strong></span></div>-->
                  <!--<div style="width: 12%">-->
                  <!--<strong v-if="plAmount < 0">{{Math.abs(totalIncome) + Math.abs(plAmount) |-->
                  <!--fixAccount}}</strong>-->
                  <!--<strong v-else>{{totalIncome | fixAccount}}</strong>-->
                  <!--</div>-->
                  <!--</div>-->
                </td>
              </tr>
              <!--<tr>-->
              <!--<th style="text-align: left; padding: 0px 5px;" v-if="pl < 0">Net Loss transferred from P/L-->
              <!--</th>-->
              <!--<th v-if="pl < 0" style="text-align: right; padding: 0px 5px">{{ pl | fixAccount }}</th>-->
              <!--<th style="text-align: left; padding: 0px 5px;" v-if="pl > 0 ">Net Profit transferred from-->
              <!--P/L-->
              <!--</th>-->
              <!--<th v-if="pl > 0" style="text-align: right; padding: 0px 5px">{{ pl | fixAccount }}</th>-->

              <!--&lt;!&ndash;<th style="text-align: left; padding: 0px 5px;">Profit/Loss transferred from P/L</th>&ndash;&gt;-->
              <!--&lt;!&ndash;<th style="text-align: right; padding: 0px 5px;">{{ pl | fixAccount}}</th>&ndash;&gt;-->
              <!--</tr>-->
              <tr v-if="tree.length < 1">
                <td colspan="2" style="text-align: center">
                  <strong>No records available.</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <table border="1" padding="0" style="" v-if="!vertical">
            <thead>
              <tr>
                <th style="text-align: left; width: calc(50% - 100px)">
                  Particulars
                </th>
                <th style="width: 100px">Amount (Rs.)</th>
                <th style="text-align: left; width: 40%">Particulars</th>
                <th style="width: 100px">Amount (Rs.)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="2" valign="top" style="position: relative">
                  <balance-tree-horizontal
                    :tree-data="liabilities"
                    :root="0"
                  ></balance-tree-horizontal>
                  <div class="bar"></div>
                </td>
                <td colspan="2" valign="top" style="position: relative">
                  <balance-tree-horizontal
                    :tree-data="assets"
                    :root="0"
                  ></balance-tree-horizontal>
                  <div class="bar"></div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th v-if="pl > 0">Net Profit transferred from P/L</th>
                <th v-if="pl > 0" style="text-align: right">
                  {{ pl | fixAccount }}
                </th>
                <th v-if="pl < 0" colspan="3">Net Loss transferred from P/L</th>
                <th v-if="pl < 0" style="text-align: right">
                  {{ pl | fixAccount }}
                </th>
              </tr>

              <tr v-if="diffAL > 0">
                <th>Difference</th>
                <th style="text-align: right">{{ diffAL | fixAccount }}</th>
              </tr>
              <tr v-if="diffAL < 0">
                <th colspan="3">Difference</th>
                <th style="text-align: right">
                  {{ diffAL | fixAccount }}
                </th>
              </tr>
              <tr>
                <th>Total</th>
                <th style="text-align: right">
                  <span v-if="liabilities">
                    <span v-if="diffAL > 0">{{
                      (Math.abs(liabilitiesSum) + Math.abs(diffAL)) | fixAccount
                    }}</span>
                    <span v-else>{{ liabilitiesSum | fixAccount }} </span>
                  </span>
                </th>
                <th>Total</th>
                <th style="text-align: right">
                  <span v-if="assets">
                    <span v-if="diffAL < 0">{{
                      (Math.abs(assetsSum) + Math.abs(diffAL)) | fixAccount
                    }}</span>
                    <span v-else>{{ assetsSum | fixAccount }} </span>
                  </span>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// import Spinner from '@/components/Partials/Spinner'
// import Notification from '@/components/Partials/Notification'

import BalanceTree from '@/components/Generic/BalanceTree/Tree';
import BalanceTreeHorizontal from '@/components/Generic/BalanceTree/TreeH';
import { mapState } from 'vuex';
import { passDataToExternalDomain } from '../../../../library/helpers';

const NepaliDate = require('nepali-date');
const nd = new NepaliDate();

export default {
  name: 'balance-sheet',
  data: () => ({
    tree: [],
    vertical: true,
    assets: [],
    Liabilities: [],
    assetsDiff: 0,
    liabilitiesDiff: 0,
    assetsSum: 0,
    liabilitiesSum: 0,

    diff: 0,
    pl: 0,

    frmDate: nd.format('YYYY-MM-DD'),
    toDate: nd.format('YYYY-MM-DD'),
  }),
  created() {},
  computed: {
    ...mapState(['trialbalance']),
    diffAL() {
      return Math.abs(this.assetsSum) - Math.abs(this.liabilitiesSum);
    },
  },
  mounted() {
    this.getTrials();
  },
  watch: {
    'trialbalance.filter_date.from_date': function (val) {
      this.trialbalance.filter_date.from_date = this.dateFormat(val);
    },
    'trialbalance.filter_date.to_date': function (val) {
      this.trialbalance.filter_date.to_date = this.dateFormat(val);
    },
  },
  methods: {
    logClick(node) {
      //console.log(`Clicked: ${node}`);
    },
    getClosing(node) {
      return node.balance - node.sum_cr + node.sum_dr;
      // return node.balance
    },
    dateFormat(data) {
      if (data.length === 8)
        return (data =
          data.substr(0, 4) + '-' + data[4] + data[5] + '-' + data.slice(-2));
      else return data;
    },
    getTrials() {
      this.$rest
        .get(
          '/api/trial-balance?type=balance-sheet&frmDate=' +
            this.trialbalance.filter_date.from_date +
            '&toDate=' +
            this.trialbalance.filter_date.to_date
        )
        .then(({ data }) => {
          this.tree = data.filter((item) =>
            ['Assets', 'Liabilities'].includes(item.name)
          );
          // console.log(this.tree);

          this.assets = this.tree[0];
          this.liabilities = this.tree[1];

          let pl = data.filter((item) =>
            ['Income', 'Expenses'].includes(item.name)
          );

          // console.log(pl[0]);
          // this.pl = pl[0].balance + pl[1].balance;
          this.pl =
            Math.abs(this.getClosing(pl[1])) - Math.abs(this.getClosing(pl[0]));

          this.assetsSum =
            this.assets.balance - this.assets.sum_cr + this.assets.sum_dr;
          if (this.pl < 0)
            this.assetsSum = Math.abs(this.assetsSum) + Math.abs(this.pl);

          this.liabilitiesSum =
            this.liabilities.balance -
            this.liabilities.sum_cr +
            this.liabilities.sum_dr;
          if (this.pl > 0)
            this.liabilitiesSum =
              Math.abs(this.liabilitiesSum) + Math.abs(this.pl);

          // created by yubraj
          /** Check if liabilities is greater than assets **/
          // if (this.liabilities && (this.liabilities.balance + this.pl) > this.assets.balance) this.assetsDiff = (this.liabilities.balance + this.pl) - this.assets.balance;
          // /** Check if assets is greater than liabilities **/
          // if (this.assets && this.assets.balance > (this.liabilities.balance + this.pl)) this.liabilitiesDiff = this.assets.balance - (this.liabilities.balance + this.pl);

          // created by pila
          // tree[0] = assets tree[1] = liablities

          // this.diff = Math.abs(this.getClosing(this.tree[1])) - Math.abs(this.getClosing(this.tree[0]))
        });
    },
    downloadBalanceSheet() {
      let reportType = 'balance';
      if (this.status === 'detail') {
        reportType = 'balance_detail';
      }
      this.$rest.get('api/download/trial-balance').then((data) => {
        let url =
          data.data.data.download_url +
          '?type=balance&frmDate=' +
          this.trialbalance.filter_date.from_date +
          '&toDate=' +
          this.trialbalance.filter_date.to_date +
          '&reportType=' +
          reportType +
          '&hideZero=' +
          true;
        passDataToExternalDomain();
        setTimeout(() => {
          window.open(url);
        }, 300);
      });
    },
  },
  components: { BalanceTree, BalanceTreeHorizontal },
  filters: {
    fixAccount(item) {
      if (!isNaN(item))
        return item >= 0 ? item.numberFormat() : `${(-item).numberFormat()}`;
    },
  },
};
</script>
<style lang="scss">
table {
  width: 100%;
  background: white;
  thead {
    th {
      padding: 5px;
    }
  }
  tbody {
    .bar {
      position: absolute;
      width: 1px;
      height: 100%;
      background: black;
      right: 100px;
      top: 0;
    }
  }
  tfoot {
    tr {
      th {
        padding: 0 5px;
        &:first-child,
        &:nth-child(3) {
          text-align: left;
        }
        &:last-child,
        &:nth-child(2) {
          text-align: right;
        }
      }
    }
  }
  .p {
    margin-right: 116px !important;
    padding-left: 6px;
    padding-right: 9px;
    /*border-right: solid 1px;*/
  }
  .pl {
    margin-right: 76px !important;
    padding-left: 6px;
    padding-right: 9px;
    /*border-right: solid 1px;*/
  }
  .pldiv {
    text-align: right;
    border: solid 1px;
    display: flex;
  }
}
</style>
